import React, { useState } from "react"
import { Modal, Form } from "react-bootstrap"
import WebriQForm from "@webriq/gatsby-webriq-form"

const FollowUpModal = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div className="page-wrapper">
      <h6 onClick={handleShow} className="text-gold followup-cta">
        Request A Follow Up
      </h6>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="followup-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="f-900 ml-4">Request A Follow Up</Modal.Title>
        </Modal.Header>

        <Modal.Body className="followup-modal-body">
          <div className="followup-form">
            <div className="container">
              <div className="row">
                <div className="followup-body-form">
                  <WebriQForm
                    name="Request A Follow Up"
                    id="followup-form"
                    className="follow-form"
                    data-form-id="db0233a4-a6bb-4896-a520-dbc410785447"
                    data-thankyou-url="/thank-you"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control agent-input"
                            type="text"
                            name="firstname"
                            placeholder="First Name"
                            required
                          />
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control agent-input"
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control agent-input"
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required
                          />
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control agent-input"
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control agent-input"
                            type="text"
                            name="company"
                            placeholder="Company"
                            required
                          />
                        </div>
                        <div class="form-group mb-2">
                          <label for="subject">Subject:</label>
                          <select
                            class="form-control"
                            id="subject"
                            name="subject"
                          >
                            <option>Sales</option>
                            <option>Custome Service</option>
                            <option>Cancellations/Reinstatements</option>
                            <option>Quoting</option>
                            <option>Web Help</option>
                            <option>General</option>
                          </select>
                        </div>
                        <div className="form-group mb-4">
                          <textarea
                            className="form-control agent-input"
                            type="text"
                            name="message"
                            placeholder="Your Message"
                            rows="3"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <div className="webriq-recaptcha" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group followup-submit">
                          <button
                            className="btn btn-primary followup-cta-submit"
                            type="submit"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </WebriQForm>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default FollowUpModal
