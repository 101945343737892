import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import Follow from "../modal/follow-up"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/premium-finance">Premium Finance</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about-us">About Us</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/solutions">Solutions</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/contact-us">Contact Us</Link>
      </Nav.Item>
      <Nav.Item className="mb-3 text-left mt-3 mb-4">
        <Follow />
      </Nav.Item>
      <Nav.Item>
        <a href="tel:18005068901" className="sidebar-hero-phone">
          <svg
            width="12"
            height="12"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1"
          >
            <path
              d="M19.2717 20.0625L20.1702 16.1172C20.2483 15.8307 20.2092 15.5573 20.053 15.2969C19.9228 15.0365 19.7275 14.8411 19.467 14.7109L15.2483 12.9141C14.9879 12.8099 14.7275 12.7839 14.467 12.8359C14.2066 12.9141 13.9853 13.0573 13.803 13.2656L12.0842 15.375C10.7301 14.6979 9.51912 13.8255 8.45142 12.7578C7.38371 11.6901 6.51131 10.4792 5.83423 9.125L7.9436 7.40625C8.15194 7.22396 8.28215 7.0026 8.33423 6.74219C8.41235 6.48177 8.39933 6.22135 8.29517 5.96094L6.49829 1.74219C6.36808 1.48177 6.17277 1.27344 5.91235 1.11719C5.65194 0.986979 5.3785 0.960937 5.09204 1.03906L1.14673 1.9375C0.886312 2.01562 0.664958 2.15885 0.482666 2.36719C0.300374 2.60156 0.209229 2.86198 0.209229 3.14844C0.209229 6.3776 1.01652 9.3724 2.6311 12.1328C4.21965 14.8411 6.35506 16.9766 9.03735 18.5391C11.8238 20.1797 14.8446 21 18.0999 21C18.3603 21 18.5946 20.9089 18.803 20.7266C19.0374 20.5443 19.1936 20.3229 19.2717 20.0625ZM1.45923 3.14844L5.36548 2.25L7.16235 6.46875L4.31079 8.77344C5.24829 10.8047 6.32902 12.4323 7.55298 13.6562C8.77694 14.8802 10.4045 15.9609 12.4358 16.8984L14.7405 14.0469L18.9592 15.8438L18.0608 19.75C15.066 19.75 12.2795 18.9948 9.70142 17.4844C7.17537 16 5.18319 14.0078 3.72485 11.5078C2.21444 8.92969 1.45923 6.14323 1.45923 3.14844Z"
              fill="#000"
            />
          </svg>
          1.800.506.8901
        </a>
      </Nav.Item>
      <Nav.Item>
        <Link to="/make-a-payment" className="sidebar-hero-phone">
          MAKE A PAYMENT
        </Link>
      </Nav.Item>
      <Nav.Item>
        <a
          href="https://www.nationalpartners.com/agents/"
          className="sidebar-hero-agent"
        >
          <svg
            width="9"
            height="12"
            viewBox="0 0 9 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1"
          >
            <path
              d="M0 12H8.57143V9.85715C8.57052 9.06178 8.25416 8.29924 7.69175 7.73683C7.12933 7.17442 6.3668 6.85805 5.57143 6.85715H3C2.20463 6.85805 1.44209 7.17442 0.879682 7.73683C0.317269 8.29924 0.000907441 9.06178 0 9.85715V12Z"
              fill="white"
            />
            <path
              d="M1.28564 3C1.28564 3.59334 1.46159 4.17336 1.79124 4.66671C2.12088 5.16006 2.58942 5.54458 3.13759 5.77164C3.68577 5.9987 4.28897 6.05811 4.87092 5.94236C5.45286 5.8266 5.98741 5.54088 6.40697 5.12132C6.82652 4.70176 7.11225 4.16721 7.228 3.58527C7.34376 3.00333 7.28435 2.40013 7.05728 1.85195C6.83022 1.30377 6.4457 0.835235 5.95236 0.505591C5.45901 0.175947 4.87899 0 4.28564 0C3.49 0 2.72693 0.31607 2.16432 0.87868C1.60172 1.44129 1.28564 2.20435 1.28564 3Z"
              fill="white"
            />
          </svg>
          AGENT
        </a>
      </Nav.Item>
      <Nav.Item>
        <a
          href="https://www.nationalpartners.com/clients/"
          className="sidebar-hero-agent"
        >
          <svg
            width="9"
            height="12"
            viewBox="0 0 9 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1"
          >
            <path
              d="M0 12H8.57143V9.85715C8.57052 9.06178 8.25416 8.29924 7.69175 7.73683C7.12933 7.17442 6.3668 6.85805 5.57143 6.85715H3C2.20463 6.85805 1.44209 7.17442 0.879682 7.73683C0.317269 8.29924 0.000907441 9.06178 0 9.85715V12Z"
              fill="white"
            />
            <path
              d="M1.28564 3C1.28564 3.59334 1.46159 4.17336 1.79124 4.66671C2.12088 5.16006 2.58942 5.54458 3.13759 5.77164C3.68577 5.9987 4.28897 6.05811 4.87092 5.94236C5.45286 5.8266 5.98741 5.54088 6.40697 5.12132C6.82652 4.70176 7.11225 4.16721 7.228 3.58527C7.34376 3.00333 7.28435 2.40013 7.05728 1.85195C6.83022 1.30377 6.4457 0.835235 5.95236 0.505591C5.45901 0.175947 4.87899 0 4.28564 0C3.49 0 2.72693 0.31607 2.16432 0.87868C1.60172 1.44129 1.28564 2.20435 1.28564 3Z"
              fill="white"
            />
          </svg>
          INSURED
        </a>
      </Nav.Item>
    </Nav>
  </div>
)
